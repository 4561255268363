<template>
  <div class="tleft-child">
    <p
      v-if="
        product.hiddenType &&
          product.hiddenType === 'cookie-stamp-text' &&
          product.message
      "
    >
      <span class="bold tblack">Personnalisation :</span>
      {{ product.message }}
      <br />
    </p>

    <p v-if="product.name">
      <span
        v-if="
          product.hiddenType === 'deco-phrase' ||
            product.hiddenType === 'frame-phrase' ||
            product.hiddenType === 'cookie-cutter-stamp-set'
        "
      >
        <span v-if="product.name2" class="bold tblack">Initiales : </span>
        <span v-else class="bold tblack">Initiale :</span>

        {{ product.name }}

        <span v-if="product.name2"> & {{ product.name2 }}</span>
      </span>
      <span v-else>
        <span v-if="product.name2" class="bold tblack"
          >Prénoms/Mots :
        </span>
        <span v-else class="bold tblack">Prénom/Mot :</span>

        {{ product.name }}

        <span v-if="product.name2"> & {{ product.name2 }}</span>
      </span>
    </p>

    <p v-if="product.typo">
      <span class="bold tblack">Police d'écriture :</span>
      {{ product.typo.title }}
    </p>

    <p v-if="product.color && product.color.name !== 'no-color'">
      <span class="bold tblack">Couleur {{ productType }}:</span>
      {{ product.color.title }}
    </p>

    <p v-if="product.colorSnowflake">
      <span class="bold tblack">Couleur flocon :</span>
      {{ product.colorSnowflake.title }}
    </p>

    <p v-if="product.colorPlate">
      <span class="bold tblack">Couleur plaquette :</span>
      {{ product.colorPlate.title }}
    </p>

    <p v-if="product.colorBall">
      <span class="bold tblack">Couleur boule :</span>
      {{ product.colorBall.title }}
    </p>

    <p v-if="product.colorArk1">
      <span class="bold tblack">Couleur arche extérieure :</span>
      {{ product.colorArk1.title }}
    </p>

    <p v-if="product.colorArk2">
      <span class="bold tblack">Couleur arche centrale :</span>
      {{ product.colorArk2.title }}
    </p>

    <p v-if="product.colorArk3">
      <span class="bold tblack">Couleur arche intérieure :</span>
      {{ product.colorArk3.title }}
    </p>

    <p v-if="product.size">
      <span class="bold tblack">Taille :</span> {{ product.size.title }}
      <span v-if="product.size.option">- {{ product.size.option }}</span>
    </p>

    <p v-if="product.thickness">
      <span class="bold tblack">Epaisseur :</span>
      {{ product.thickness.title }}
    </p>

    <p v-if="product.width">
      <span class="bold tblack">Taille :</span> {{ product.width.title }}
      <span v-if="product.width.option">- {{ product.width.option }}</span>
    </p>

    <p v-if="product.date">
      <span class="bold tblack">Date :</span>
      {{ stringPrettyDate(product.date) }}
    </p>

    <p
      v-if="
        product.hiddenType &&
          product.hiddenType !== 'cookie-stamp-text' &&
          product.message
      "
    >
      <span class="bold tblack">Personnalisation :</span>
      {{ product.message }}
    </p>

    <p v-if="product.symbole">
      <span class="bold tblack">Symbole :</span>
      {{ product.symbole.name || 'Pas de symbole' }}
    </p>

    <p v-if="product.informations && product.informations.date">
      <span
        class="bold tblack"
        v-if="
          product.hiddenType === 'baptism-classic' ||
            product.hiddenType === 'baptism-godparents'
        "
      >
        Date du baptême :
      </span>
      <span class="bold tblack" v-else>Date de naissance :</span>

      {{ stringPrettyDate(product.informations.date) }}
    </p>

    <p v-if="product.informations && product.informations.godmother">
      <span class="bold tblack">Prénom de la marraine :</span>
      {{ product.informations.godmother }}
    </p>

    <p v-if="product.informations && product.informations.godfather">
      <span class="bold tblack">Prénom du parrain :</span>
      {{ product.informations.godfather }}
    </p>

    <p v-if="product.informations && product.informations.hour">
      <span class="bold tblack">Heure de naissance :</span>
      {{ product.informations.hour }}
    </p>

    <p v-if="product.informations && product.informations.weight">
      <span class="bold tblack">Poids :</span>
      {{ formatWeight(product.informations.weight) }}
    </p>

    <p v-if="product.informations && product.informations.height">
      <span class="bold tblack">Taille :</span>
      {{ product.informations.height }} cm
    </p>

    <p v-if="product.informations2 && product.informations2.date">
      <span class="bold tblack">Date de naissance :</span>
      {{ stringPrettyDate(product.informations2.date) }}
    </p>

    <p v-if="product.informations2 && product.informations2.hour">
      <span class="bold tblack">Heure de naissance :</span>
      {{ product.informations2.hour }}
    </p>

    <p v-if="product.informations2 && product.informations2.weight">
      <span class="bold tblack">Poids :</span>
      {{ product.informations2.weight }} kg
    </p>

    <p v-if="product.informations2 && product.informations2.height">
      <span class="bold tblack">Taille :</span>
      {{ product.informations2.height }} cm
    </p>

    <p v-if="product.startDate && product.endDate">
      <span class="bold tblack">Année(s) :</span>
      {{ product.startDate }} - {{ product.endDate }}
    </p>

    <p v-if="product.hiddenType === 'creativo-stamps'">
      <span class="bold tblack">Collection :</span>
      Creativo
    </p>

    <p v-if="product.hiddenType === 'cracker-banana-stamps'">
      <span class="bold tblack">Collection :</span>
      Cracker Banana
    </p>

    <template v-if="showOptions">
      <p v-for="(option, index) in product.options" :key="index">
        <span class="bold tblack">Option :</span>
        {{ option.title }}
      </p>
    </template>
  </div>
</template>

<script>
import { formatDate } from '../../mixins';

export default {
  mixins: [formatDate],

  props: {
    product: {
      type: Object,
      default: () => {},
    },

    showOptions: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    productType() {
      if (this.product?.hiddenType?.includes('mini-planter')) {
        return 'pot';
      }

      if (this.product?.hiddenType === 'rainbow-frame') {
        return 'prénom';
      }

      return '';
    },
  },

  methods: {
    formatWeight(weight) {
      const [kg, g] = weight.split('.');
      return `${kg}kg ${g}`;
    },
  },
};
</script>
